import { Component, OnInit, Input } from '@angular/core';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-toolbar-notification',
  templateUrl: './toolbar-notification.component.html',
  styleUrls: ['./toolbar-notification.component.scss']
})

export class ToolbarNotificationComponent implements OnInit {
    @Input() notifications = [];
    getNotification: Subscription;
    unread_notification = 0;

    constructor() {}

    ngOnInit() {
        this.unread_notification = parseInt(sessionStorage.getItem('unviewed_notification'));
        const myTotal = Observable.interval(3000);
        this.getNotification = myTotal.subscribe(
            () => {
                this.unread_notification = parseInt(sessionStorage.getItem('unviewed_notification'));
            }
        );
    }

}
