import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import {AppService} from '../services/app.service';

@Injectable()
export class MessagingService {

    currentMessage = new BehaviorSubject(null);
    message: string;
    setObject;

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private httpService: AppService
    ) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        );
    }

    /**
     * update token in firebase database
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    updateToken(token) {

        sessionStorage.setItem('firebase-auth', token);

        // we can change this function to request our backend service
        this.setObject = {
            resource: [
                {
                    token: token
                }
            ]
        };


        this.httpService.POST(this.httpService.DISPOSITIVO_NOTIFICACAO, this.setObject).subscribe(
            (onNext: any) => {
                console.log('Cliente registrado');
            },
            (OnError: any) => {
                if ( OnError.error.error.context.resource[0].code === "23000") {
                    console.log('Cliente já Registrado');
                } else {
                    console.log('Erro ao registrar');
                }
            }
        );

    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token) => {
                this.updateToken(token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                this.currentMessage.next(payload);

                this.message = JSON.stringify(payload);
                sessionStorage.setItem('notification', this.message);
            });
    }
}
