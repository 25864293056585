import { Component, OnInit, Input } from '@angular/core';
import { Observable, fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { SecureStorageService } from '../../services/secure-storage.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

@Input() snav;
@Input() sidebar;
@Input() drawer;
@Input() matDrawerShow;

searchOpen = false;

online$: Observable<boolean>;
name: string;
nome_cliente: string;

  constructor(
    private secureStorage: SecureStorageService
    ) {
      this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.networkStatus();
    }

    device = null;

public networkStatus() {
  this.online$.subscribe(value => {
  this.name = `${value}`;
  });
}

ngOnInit() {
  this.nome_cliente = this.secureStorage.ls.get("vbox_clientName");
  this.device = sessionStorage.getItem("on_device");
  console.log(this.device);
}

}
