import { Component, OnInit } from '@angular/core';
import * as screenfull from "screenfull";
import {Screenfull} from "screenfull";

@Component({
  selector: 'app-fullscreen',
  templateUrl: './fullscreen.component.html',
  styleUrls: ['./fullscreen.component.scss']
})
export class FullscreenComponent implements OnInit {
isFullscreen = false;
constructor() { }

ngOnInit() {
}


toggleFullscreen() {
    let sf = <Screenfull>screenfull;
    /*
    if (sf.enabled) {
      sf.toggle();
    }
    */
  }
/*
toggleFullscreen() {
if (screenfull.enabled) {
screenfull.toggle();
this.isFullscreen = !this.isFullscreen;
}
}
*/

}
