import 'rxjs/add/operator/do';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AppService } from '../services/app.service';
import { Injectable } from '@angular/core';
import { SecureStorageService } from '../services/secure-storage.service';
import { Observable } from 'rxjs';
import { AutenticationService } from '../autentication.service';
import {BsModalService} from "ngx-bootstrap/modal";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    public auth: AppService,
    private secureStorage: SecureStorageService,
    private authService: AutenticationService,
    private modal: BsModalService,
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token: string = this.secureStorage.ls.get('vbox_token');
    const api: string = this.secureStorage.ls.get('vbox_api');
    const logged: boolean = this.secureStorage.ls.get('logged');

    // set token header
    if (token) {
      request = request.clone({
        headers: request.headers.set(
          'X-DreamFactory-Session-Token', token
        )
      });
    }

    // Api header
    if (!request.headers.has('X-Dreamfactory-API-Key')) {
      request = request.clone({
        headers: request.headers.set('X-Dreamfactory-API-Key', api)
      });
    }

    // Content type header
    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        headers: request.headers.set('Content-Type', 'application/json')
      });
    }

    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (logged === true && err.status === 401) {
          // redirect to the login route
          sessionStorage.setItem('message', 'Sessão desativada por inatividade, faça login novamente.');
          this.authService.logoutAuth();
        }
        /*
        if (err.status === 404) {
          // redirect to the login route
          this.authService.interceptor_message = 'Sessão desativada por inatividade, faça login novamente';
          this.authService.logoutAuth();
        }
        */
        if (logged === true && err.status === 403) {
          // redirect to the login route
          sessionStorage.setItem('message', 'Sem permissão, faça login novamente.');
          this.authService.logoutAuth();
        }
      }
    });

   /*
    return next.handle(request).pipe( map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log('event--->>>', event);
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }), catchError((error: HttpErrorResponse) => {
        let data = {};
        data = { reason: error && error.error.reason ? error.error.reason : '', status: error.status };
        // this.errorDialogService.openDialog(data);
        console.log('Error: ' + data);
        return throwError(error);
      }) );
      */
  }

  // Close all modals
  private closeAllModals() {
    for (let i = 1; i <= this.modal.getModalsCount(); i++) {
      this.modal.hide(i);
    }
  }
}
