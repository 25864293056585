import { Injectable, OnInit } from '@angular/core';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/shareReplay';
import { SecureStorageService } from './secure-storage.service';



// CONFIGURATION APP FOR CLIENT
let SERVER = 'http://3.208.251.69:';
let PORT = "8080";
let BASE_URL_CLIENT = '/api/v2/08c88a82-4b39-4f22-9818-2f2838085f3d';
let API_KEY = '347d71e0c95772707f6ef1610db9fe8051c69ea6e774bd7ce60b2e6049ca55e8';

let LOGIN = SERVER + PORT + '/api/v2/user/session';
let LOGOUT = SERVER + PORT + '/api/v2/user/session?session_token=';

@Injectable()
export class AppService implements OnInit {

    httpOptions: any;
    SYSTEM_USER = SERVER + PORT + '/api/v2/system/user?related=user_to_app_to_role_by_user_id';
    ROLE = SERVER + PORT + '/api/v2/system/role/';
    USER = SERVER + PORT + '/api/v2/system/user';
    LOCAL_USER = SERVER + PORT + BASE_URL_CLIENT +  '/_table/user';
    CURRENCY_EVENT = SERVER + PORT + BASE_URL_CLIENT +  '/_table/currency_event';
    MACHINE = SERVER + PORT + BASE_URL_CLIENT +  '/_table/machine';
    MACHINE_TYPE = SERVER + PORT + BASE_URL_CLIENT +  '/_table/machine_type';
    MACHINE_TO_VBOX = SERVER + PORT + BASE_URL_CLIENT +  '/_table/machine_node';
    VBOX =  SERVER + PORT + BASE_URL_CLIENT +  '/_table/node';
    SANGRIA = SERVER + PORT + '/api/v2/vm_sangria';
    COMMANDS = SERVER + PORT + '/api/v2/vm_publish_command_mqtt';
    OPERATING_HOUR = SERVER + PORT + BASE_URL_CLIENT +  '/_table/machine_operating_hours';
    PREMIO = SERVER + PORT + BASE_URL_CLIENT +  '/_table/product_event';
    SANGRIA_EXECUTADA = SERVER + PORT + BASE_URL_CLIENT +  '/_table/extraction';
    TRANSACAO_SANGRIA = SERVER + PORT + BASE_URL_CLIENT +  '/_table/extraction_transactions';
    RELATORIO_NOTA = SERVER + PORT + BASE_URL_CLIENT +  '/_proc/relatorio_qtt_notas';
    PRODUTO = SERVER + PORT + BASE_URL_CLIENT + '/_table/product';
    NOTIFICATION = SERVER + PORT + BASE_URL_CLIENT + '/_table/notification';
    TAG_LIVRE = SERVER + PORT + BASE_URL_CLIENT + '/_table/received_tag';
    PRODUTO_MAQUINA = SERVER + PORT + BASE_URL_CLIENT + '/_table/machine_product';
    INFORMACAO_CLIENTE = SERVER + PORT + BASE_URL_CLIENT + '/_table/client_payment_information';
    FATURA = SERVER + PORT + BASE_URL_CLIENT + '/_table/invoice';
    DISPOSITIVO_NOTIFICACAO = SERVER + PORT + BASE_URL_CLIENT + '/_table/device';
    GRUPO = SERVER + PORT + BASE_URL_CLIENT + '/_table/group';
    GRUPO_USUARIO = SERVER + PORT + BASE_URL_CLIENT + '/_table/user_group';
    BOLETO_PAGSEGURO = 'https://ws.pagseguro.uol.com.br/recurring-payment/boletos?';
    CAMPANHA = SERVER + PORT + BASE_URL_CLIENT + '/_table/campaign';
    REGRA_BONUS = SERVER + PORT + BASE_URL_CLIENT + '/_table/machine_bonus_template';
    MAQUINA_CAMPANHA = SERVER + PORT + BASE_URL_CLIENT + '/_table/machine_campaign';

    constructor(
        private http: HttpClient,
        private secureStorage: SecureStorageService
    ) { }

    // Init
    ngOnInit(): void {
        // DF KEYs
        API_KEY = '347d71e0c95772707f6ef1610db9fe8051c69ea6e774bd7ce60b2e6049ca55e8';
        this.secureStorage.ls.set('vbox_api', API_KEY);
    }

    // Login user app
    loginUser(resource) {
        // tslint:disable-next-line:prefer-const
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        console.log(LOGIN, resource, this.httpOptions);
        return this.http.post(LOGIN, resource, this.httpOptions).timeout(10000);
    }

    // Logout user
    logoutUser(param) {
        return this.http.delete(LOGOUT + param, this.httpOptions).timeout(40000);
    }

    // Method GET
    GET(url, resource) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-DreamFactory-Session-Token': this.secureStorage.ls.get('server_token'),
                'X-Dreamfactory-API-Key': API_KEY
            })
        };

        return this
            .http
            .get(url + resource, this.httpOptions).timeout(30000);

    }

    // Method GET By ID
    GET_BY_ID(url, id, resource) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-DreamFactory-Session-Token': this.secureStorage.ls.get('server_token'),
                'X-Dreamfactory-API-Key': API_KEY
            })
        };

        return this
            .http
            .get(url + id + resource, this.httpOptions).timeout(30000);

    }

    // Method PATCH
    PATCH(url, id, resource) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-DreamFactory-Session-Token': this.secureStorage.ls.get('server_token'),
                'X-Dreamfactory-API-Key': API_KEY
            })
        };

        return this
            .http
            .patch(url + '/' + id, resource, this.httpOptions).timeout(30000);
    }

    // Method DELETE
    DELETE(url, id) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-DreamFactory-Session-Token': this.secureStorage.ls.get('server_token'),
                'X-Dreamfactory-API-Key': API_KEY
            })
        };

        return this
            .http
            .delete(url + '/' + id, this.httpOptions).timeout(30000);
    }

    // Method POST
    POST(url,  resource) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-DreamFactory-Session-Token': this.secureStorage.ls.get('server_token'),
                'X-Dreamfactory-API-Key': API_KEY
            })
        };

        return this
            .http
            .post(url, resource, this.httpOptions).timeout(30000);
    }

    // Method POST
    PUT(url, id, resource) {

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-DreamFactory-Session-Token': this.secureStorage.ls.get('server_token'),
                'X-Dreamfactory-API-Key': API_KEY
            })
        };

        return this
            .http
            .put(url + id, resource, this.httpOptions).timeout(30000);
    }

}
