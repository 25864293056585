export const environment = {
  production: false,
  hmr: false,
  firebase: {
    apiKey: "AIzaSyCKkT9ohk-y49QN6cp3KnZ2IFWcT8lRfRA",
    authDomain: "vbox-spolus.firebaseapp.com",
    databaseURL: "https://vbox-spolus.firebaseio.com",
    projectId: "vbox-spolus",
    storageBucket: "vbox-spolus.appspot.com",
    messagingSenderId: "965148603211",
    appId: "1:965148603211:web:9bbef3556d28103c"
  }
};
