import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import * as SecureLS from 'secure-ls';



@Injectable()
export class SecureStorageService {
 ls: any;
    constructor(
        private http: Http
    ) {
        this.ls = new SecureLS({
            encodingType: 'aes',
            isCompression: true,
            encryptionSecret: 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'
        });
    }

}
