import { NgModule } from '@angular/core';
import {
        MatCardModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatToolbarModule,
        MatIconModule,
        MatCheckboxModule,
        MatListModule,
       } from '@angular/material';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PagesRouterModule } from './pages.routes';
import { CoreModule } from '../core/core.module';
import { SecureStorageService } from '../services/secure-storage.service';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
    imports: [
        MatCardModule,
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatInputModule,
        MatToolbarModule,
        MatIconModule,
        MatCheckboxModule,
        MatListModule,
        CoreModule,
        PagesRouterModule,
        ModalModule.forRoot()
    ],
    declarations: [],
    exports: [
    ],
    providers: [
        SecureStorageService
    ]
})
export class PagesModule {
}
