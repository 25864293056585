import {Component, OnInit, ViewChild, ViewEncapsulation, Input, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {SecureStorageService} from '../../services/secure-storage.service';



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})


export class SidebarComponent implements OnInit {

  // @Input() sidebar;
  // @ViewChild('open_menu') public snav: MatButton;
  app_version: string;
  deviceInfo = null;

  constructor(
    public route: Router,
    private secureStorage: SecureStorageService
  ) {}

  // tslint:disable-next-line:member-ordering
  today: number = Date.now();

  ngOnInit() {
    this.app_version = this.secureStorage.ls.get("vbox_app_version");
  }
  
  onClickedOutside() {
    let element: HTMLElement = document.getElementById('open_menu') as HTMLElement;
    element.click();
  }

}
