import { Component, OnInit, Input } from '@angular/core';
import { menus_administrativo } from './menu-element';
import { SecureStorageService } from '../../services/secure-storage.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

    @Input() iconOnly = false;
    public menus; // = menus;

    constructor(
      private secureStorage: SecureStorageService
    ) { }

    ngOnInit() {

      this.menus = menus_administrativo;

      // if ( this.secureStorage.ls.get('profile') === 2) {
      //   this.menus = menus_administrativo;
      // }

    }

}
