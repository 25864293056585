import { Component, OnInit, Input, HostListener, ElementRef } from '@angular/core';
import { AutenticationService } from '../../autentication.service';
import { Router } from '@angular/router';
import { SecureStorageService } from '../../services/secure-storage.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})

export class UserMenuComponent implements OnInit {

isOpen = false;
firstName: string;
lastName: string;
fullName: string;

@Input() currentUser = null;
@HostListener('document:click', ['$event', '$event.target'])
onClick(event: MouseEvent, targetElement: HTMLElement) {
    if (!targetElement) {
    return;
    }

const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
    this.isOpen = false;
    }
}

constructor(
    private router: Router,
    private elementRef: ElementRef,
    private autenticatio: AutenticationService,
    private secureStorage: SecureStorageService
) { }

ngOnInit() {
    this.firstName = this.secureStorage.ls.get('vbox_firstName');
    this.lastName = this.secureStorage.ls.get('vbox_lastName');
    this.fullName = this.firstName + ' ' + this.lastName;
}

sair() {
    this.autenticatio.logoutAuth();
}

}
