import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {Router} from '@angular/router';
import { AppService } from './services/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SecureStorageService } from './services/secure-storage.service';

@Injectable()
export class AutenticationService {

constructor(
    private router: Router,
    private httpService: AppService,
    public spinner: NgxSpinnerService,
    private secureStorage: SecureStorageService
) {}

    public isEmpty(myVar): boolean {
        return (myVar && (Object.keys(myVar).length === 0));
    }

    // logout
    logoutAuth() {

        this.httpService.logoutUser(this.secureStorage.ls.get('vbox_token'))
        .subscribe(
          (onNext: any) => {
              this.router.navigate(['/login']);
              this.secureStorage.ls.removeAll();
          },
          (onError: any) => {
              this.router.navigate(['/login']);
              this.secureStorage.ls.removeAll();
          }
        );

    }
}
