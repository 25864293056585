import {Component, OnInit, OnDestroy} from '@angular/core';
import {SecureStorageService} from './services/secure-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'app';

  constructor(
      private secureStorage: SecureStorageService,
  ) {}

 ngOnInit() {
   console.log('aplicação inicializada');
     // Observable interval
 }

 ngOnDestroy() {
    console.log('aplicação encerrada');
    // this.secureStorage.ls.removeAll();
 }

}
